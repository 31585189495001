<script>
import get from 'lodash-es/get'
import ui from '/~/core/ui'
import BaseAction from '/~/components/base/action/base-action.vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import DrawerIconHeader from '/~/components/drawer/components/drawer-icon-header.vue'
import DrawerModal from '/~/components/drawer/components/drawer-modal.vue'

export default {
  name: 'app-marketplace',
  components: {
    DrawerIconHeader,
    DrawerModal,
    BaseButton,
    BaseIcon,
    BaseAction,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    return {
      ui,
    }
  },
  data: () => ({
    parentMenu: null,
  }),
  computed: {
    children() {
      return get(this.parentMenu, 'children') || get(this.item, 'children', [])
    },
  },
  methods: {
    hide() {
      this.$emit('update:visible', false)
      this.parentMenu = null
    },
    onItemClick(event, item) {
      if (item.children && item.children.length) {
        event.stopPropagation()
        this.parentMenu = item
      }
    },
  },
}
</script>

<template>
  <drawer-modal
    v-if="!ui.desktop"
    :visible="visible"
    to="modals-portal"
    fullheight
    @hide="hide"
  >
    <template #iconHeader>
      <drawer-icon-header icon="marketplace" :action="hide" simple-mobile-view>
        <template #title>
          <div
            class="relative flex w-full items-center justify-center"
            :class="parentMenu && 'px-11'"
          >
            <base-button
              v-if="parentMenu"
              icon="chevron-left"
              size="xl"
              class="absolute left-0"
              @click="parentMenu = null"
            />
            {{ parentMenu ? parentMenu.label : 'Rewards Marketplace' }}
          </div>
        </template>
      </drawer-icon-header>
    </template>
    <ul v-if="children.length" class="-mb-6 flex flex-wrap items-start">
      <li
        v-for="(child, index) in children"
        :key="child.id || index"
        class="mb-5 flex w-1/3 flex-col items-center justify-center text-center text-primary"
        @click="hide"
      >
        <div @click="onItemClick($event, child)">
          <base-action v-bind="child.action || {}">
            <div
              class="mx-auto mb-[5px] flex items-center justify-center rounded-xl"
            >
              <base-icon :svg="child.icon" class="h-20 w-20 text-primary" />
            </div>
            <span class="text-sm font-bold">
              {{ child.label }}
            </span>
          </base-action>
        </div>
      </li>
    </ul>
  </drawer-modal>
</template>
